$primary: rgb(194,75,130);

.mapWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
}
.newsHeader {
  margin: 20px
}
$primary: rgb(194,75,130);
.toprow {
  display: flex;
  flex-direction: row;
  //justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 30px 50px;
  h2 {
    font-variant: all-small-caps;
    font-size: 2.3em;
    margin-right: 50px;
  }
  a {
    text-decoration: none;
    color: $primary;
  }
}
.header {
  font-size: 2.3em;
  font-variant: all-small-caps;
  margin-left: 50px;
}
.mapWrapper {
  display: flex;
  margin-bottom: 50px;
}
.map {
  width: 80%;
  height: 600px;
  margin: auto;
}
.points {
  padding: 0 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // justify-content: space-around;
}
.point {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
  margin-top: 10px;
  .raceImage {
    width: 400px;
    height: 200px;
  }
}
.resultTable {
  padding: 0 50px 50px 50px;
}
.detailsTable {
  th {
    border: 1px solid lightgrey;
    border-collapse: collapse;
    padding: 5px;
  }
  width: 100%;
  border: 1px solid black;
  border-collapse: collapse;
}
$primary: rgb(194,75,130);
.email {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  .alignBaseLine {
    color: $primary;
    align-self: baseline;
    font-size: 1.3em;
  }
}
.form {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 0 30px 0;
}
.formWrapper {
  background-color: rgb(230,230,230);
  padding: 50px;
  margin: 20px;
  border-radius: 1em;
  width: 50%;
}
.mailHeader {
  // display: table;
  margin: 30px;
  align-self: baseline;
  // color: #000;
  // font: 30px Helvetica;
  // letter-spacing: 2px;
  // text-transform: uppercase;
  // letter-spacing: 10px;
}

form {
  //display: table;
  margin: 0 auto;

  input,
  textarea {
    // font: 18px Helvetica, Arial, sans-serif;
    box-sizing: border-box;
    display: block;
    border: none;
    padding: 20px;
    width: 100%;
    margin-bottom: 20px;
    font-size: 18px;
    outline: none;
    transition: all 0.2s ease-in-out;

    &::placeholder {
      transition: all 0.2s ease-in-out;
      color: #999;
      font: 18px Helvetica, Arial, sans-serif;
    }

    &:focus,
    &.populated {
      padding-top: 28px;
      padding-bottom: 12px;

      &::placeholder {
        color: transparent;
      }
    }
  }

  textarea {
    height: 200px;
  }

  button {
    transition: all 0.2s ease-in-out;
    font: 18px Helvetica, Arial, sans-serif;
    border: none;
    background: $primary;
    color: #fff;
    padding: 20px;
    width: 100%;
    text-transform: uppercase;
    letter-spacing: 5px;

    &:hover {
      background: #000;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 991px) {
  .formWrapper {
    width: 90%;
  }
}

@media screen and (max-width: 500px) {
  .email {
    .mailHeader {
      font-size: 2em;
    }
    .alignBaseLine {
      font-size: 1em;
    }
  }
}
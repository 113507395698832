$primary: rgb(194,75,130);

.personal {
  display: flex;
  margin: 50px;
  img {
    min-width: 200px;
    height: 200px;
    object-fit: cover;
  }
}

.markdownWrapper {
  padding: 50px;
  ul {
    margin-bottom: 20px;
  }
  li {
    margin-left: 50px;
  }
  h2 {
    font-variant: all-small-caps;
    font-size: 2.3em;
  }
  h3 {
    font-variant: all-small-caps;
    font-size: 1.5em;
  }
  a {
    text-decoration: none;
    color: $primary;
  }
  p {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 960px) {
  .personal {
    flex-direction: column;
    img {
      height: unset;
      width: 100%;
      height: 400px;
      object-position: 50% 20%;
    }
    .markdownWrapper {
      padding: 50px 0 0 0;
    }
  }
}
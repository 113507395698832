@font-face {
  font-family: 'Edmondsans';
  src: url('Edmondsans-Regular.eot');
  src: url('Edmondsans-Regular.eot?#iefix') format('embedded-opentype'),
      url('Edmondsans-Regular.woff2') format('woff2'),
      url('Edmondsans-Regular.woff') format('woff'),
      url('Edmondsans-Regular.ttf') format('truetype'),
      url('Edmondsans-Regular.svg#Edmondsans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Edmondsans'
}

h1 {
  font-size: 3em;
  font-variant: all-small-caps;
}
$primary: rgb(194,75,130);

.backgroundImage {
  // background-color: rgb(6, 6, 110);
  position: relative;
  overflow: hidden;
  img {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    animation: move 40s ease;
    -ms-animation: move 40s ease;
    -webkit-animation: move 40s ease;
    -moz-animation: move 40s ease;
  }
  @keyframes move {
    0% {
      -webkit-transform-origin: bottom left;
      -moz-transform-origin: bottom left;
      -ms-transform-origin: bottom left;
      -o-transform-origin: bottom left;
      transform-origin: bottom left;
      transform: scale(1.0);
      -ms-transform: scale(1.0);
      /* IE 9 */
      
      -webkit-transform: scale(1.0);
      /* Safari and Chrome */
      
      -o-transform: scale(1.0);
      /* Opera */
      
      -moz-transform: scale(1.0);
      /* Firefox */
    }
    100% {
      transform: scale(1.2);
      -ms-transform: scale(1.2);
      /* IE 9 */
      
      -webkit-transform: scale(1.2);
      /* Safari and Chrome */
      
      -o-transform: scale(1.2);
      /* Opera */
      
      -moz-transform: scale(1.2);
      /* Firefox */
    }
  }
}

.home__hero-section {
    color: #fff;
    padding: 160px 0;
  }
  
  .home__hero-row {
    align-items: center;
  }
  
  .row {
    display: flex;
    margin-right: -15px;
    margin-bottom: -15px;
    margin-left: -15px;
    flex-wrap: wrap;
    align-content: stretch;
  }
  
  .col {
    margin-bottom: 15px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 1;
    max-width: 50%;
    flex-basis: 50%;
  }
  
  .home__hero-text-wrapper {
    background-color: rgba(255,255,255,0.5);
    max-width: 540px;
    padding: 20px;
    // padding-bottom: 60px;
  }
  
  .top-line {
    color: $primary;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
  }
  
  .heading {
    margin-bottom: 24px;
    font-variant: all-small-caps;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: #f7f8fa;
  }
  
  .dark {
    color: #1c2237;
  }
  
  .darkBg {
    background-color: #1c2237;
  }
  
  .home__hero-subtitle {
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
  }
  
  .home__hero-img-wrapper {
    max-width: 555px;
  }
  
  // .home__hero-img {
  //   max-width: 95%;
  //   margin-top: 0;
  //   margin-right: 0;
  //   margin-left: 10px;
  //   padding-right: 0;
  // }
  
  img {
    border: 0;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
  }
  
  @media screen and (max-width: 991px) {
    .container {
      padding-right: 30px;
      padding-left: 30px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .home__hero-text-wrapper {
      padding-bottom: 65px;
    }
  
    .col {
      max-width: 100%;
      flex-basis: 100%;
    }
  }